import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import router from "./router";
import localstorageservice from "./localstorageservice";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Vuetify from 'vuetify/lib'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueRouterUserRoles from "vue-router-user-roles";
import AppAdmin from './components/admin/App.vue';
import AppOwner from "./components/Owner/AppOwner.vue";
import VueHtmlToPaper from 'vue-html-to-paper';
import moment from 'moment/src/moment';
import '@fortawesome/fontawesome-free/css/all.css'
import Echo from "laravel-echo"
window.Pusher = require('pusher-js');
Vue.use(Treeselect);
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '2f2bb15bf4a1e698e859',
  cluster: 'ap2',
  encrypted: true,
  wsPort: 6001,
  disableStats: true,
  enabledTransports: ['ws', 'wss'], // <-- only use ws and wss as valid transports
});


import {
  USER_ROLE_GUEST,
} from "./constants.js";

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(require('vue-moment'));


Vue.use(VueSweetalert2);
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.use(moment)
Vue.$cookies.config('1d')


// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const token = localstorageservice.getAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

//Add a response interceptor



axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  const originalRequest = error.config;


  if (error.response.status === 402 || error.response.status === 403) {
   


   

    var x = window.location.pathname;

    if (x == "/owner") {
      localstorageservice.clearToken();
      Vue.$cookies.remove("owner_info");
      router.push('/LoginOwner');
    }
    else
    {
      router.push('/');
      Vue.$cookies.remove("user_info");
      Vue.$cookies.remove("admin_info");
    }
   

  }
  if (error.response.status === 401) {

    originalRequest._retry = true;
    const refreshToken = localstorageservice.getRefreshToken();

    return axios.get('/auth/v2/refresh', {
        "refresh_token": refreshToken
      })
      .then(res => {
        if (res.status === 200) {

          localstorageservice.setToken(res.data.data);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + localstorageservice.getAccessToken();
          return axios(originalRequest);
        }
      })
  }
  return Promise.reject(error);
});















//Star print html to paper
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-v4-rtl/4.5.2-1/css/bootstrap.min.css',
 
  
  ]
}
Vue.use(VueHtmlToPaper, options);
// Vue.use(VueHtmlToPaper);
//End print html to paper









// Vue.use(VueSwal)
// Vue.use(VueIconPicker);



//Google Map// 
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBPFDMtjV0nyVdedEBT169klxYqGCvy3tI',
    libraries: 'places',
  },
})


//import createAuthRefreshInterceptor from 'axios-auth-refresh';
Vue.use(require('vue-moment'));






// export default new Vuetify({
//   theme: {
//     themes: {
//       light: {
//         primary: colors.green, // #E539
//         secondary: colors.red.lighten4, // #FFCDD2
//         accent: colors.indigo.base, // #3F51B5
//       },
//     },
//   },
// })



Vue.prototype.$http = axios;
axios.defaults.baseURL = 'https://tctate.com/api/api/';

Vue.use(Vuetify, {
  rtl: true


}, )



const opts = {}

// export default new Vuetify(opts)


Vue.use(router);



Vue.mixin({
  data: function () {
    return {
      Url: 'https://tctate.com/api',
      http: 'https://'
    }
  }
})

// Vue.use(Vuetify, {

//   directives: {
//     Ripple,
//   },
// })


//Auth Role
Vue.config.productionTip = false;
Vue.use(VueRouterUserRoles, {
  router
});
var x = window.location.pathname;

var auth = '';
if (x == "/") {


  if (Vue.$cookies.get('user_info') == 'no cookies' || Vue.$cookies.get('user_info') == null) {
    var user_info = {
      id: '',
      name: '',
      session: '',
      email: '',
      role: null,
      photo: ''
    };
    Vue.$cookies.set('user_info', user_info);
    user_info.role = USER_ROLE_GUEST;

    auth = Promise.resolve(Vue.$cookies.set('user_info', user_info));
  } else {
    auth = Promise.resolve(Vue.$cookies.get('user_info'.role));
  }

  //End Refresh Token
  let authenticate = auth;
  authenticate.then(user => {
    Vue.prototype.$user.set(user);
    new Vue({
      render: h => h(App),
      icons: {
        iconfont: 'fa',
      },
      vuetify: new Vuetify(opts),
      router
    }).$mount("#app");
  });





} else if (x == "/owner") {

  if (Vue.$cookies.get('owner_info') == 'no cookies' || Vue.$cookies.get('owner_info') == null) {
    var owner_info = {
      id: '',
      name: '',
      session: '',
      email: '',
      role: null,
      photo: '',
      active: ''
    };
    Vue.$cookies.set('owner_info', owner_info);
    owner_info.role = USER_ROLE_GUEST;


    auth = Promise.resolve(Vue.$cookies.set('owner_info', owner_info));
  } else {
    auth = Promise.resolve(Vue.$cookies.get('owner_info'.role));
  }

  //End Refresh Token
  let authenticate = auth;
  authenticate.then(user => {
    Vue.prototype.$user.set(user);

    new Vue({
      render: h => h(AppOwner),
      icons: {
        iconfont: 'fa',
      },
      vuetify: new Vuetify(opts),
      router
    }).$mount("#app");
  });


} else if (x == "/admin") {

  if (Vue.$cookies.get('admin_info') == 'no cookies' || Vue.$cookies.get('admin_info') == null) {
    var admin_info = {
      id: '',
      name: '',
      session: '',
      email: '',
      role: null,
      photo: ''
    };
    Vue.$cookies.set('admin_info', admin_info);
    admin_info.role = USER_ROLE_GUEST;
    auth = Promise.resolve(Vue.$cookies.set('admin_info', admin_info));
  } else {
    auth = Promise.resolve(Vue.$cookies.get('admin_info'.role));
  }

  //End Refresh Token
  let authenticate = auth;
  authenticate.then(user => {
    Vue.prototype.$user.set(user);
    new Vue({
      render: h => h(AppAdmin),
      icons: {
        iconfont: 'fa',
      },
      vuetify: new Vuetify(opts),
      router
    }).$mount("#app");
  });




}