<template>

  <div style="height:100%">


    <v-layout row wrap style="height:100%">



      <v-flex xs12 md7 sm7 class="pdr">

        <v-card style="padding:45px;" flat>
          <h1 style="text-align:right;f">اهلا وسهلا بكم في منصه تكتاتي</h1>

          <br>
          <br>
          <br>
          <br>
          <br>


          <!-- 
          <v-layout row wrap style="padding-right:10%">


            <v-flex xs6 md4 sm4>



              <v-btn class="reg_owner_btn" background-color="#fff" v-on:click="signin=false;error_msg='';e1=1"
                v-bind:class="{btn_active:!signin}" height="60">
                حساب جديد </v-btn>

            </v-flex>

            <v-flex xs6 md4 sm4>
              <v-btn class="reg_owner_btn" v-on:click="signin=true;e1=2" v-bind:class="{btn_active:signin}" height="60">
                تسجيل الدخول </v-btn>

            </v-flex>


          </v-layout> -->








          <div>
            <p style="color:#504a4a;font-size:18px;font-weight:bold">
              لانشاء حساب جديد في منصه تكتاتي

              <router-link to="/register_owner" text small color="red" style="font-weight:bold;font-size:18px">
                اضغط هنا

              </router-link>



            </p>



            <v-form ref="form_signin" v-model="valid" v-if="is_signIn">
              <v-layout row wrap>

                <v-flex xs12>
                  <div style="color:red;font-size:18px;padding-top:10px">
                    {{error_msg}}
                  </div>
                </v-flex>


                <v-flex xs12 md8 sm8>
                  <v-lable>رقم الهاتف</v-lable>
                  <v-text-field filled v-model="user.phone" placeholder="رقم الهاتف" data-vv-name="name" height="50"
                    type="number" :rules="phoneRules" required></v-text-field>



                </v-flex>


                <v-flex xs12 md8 sm8>
                  <v-lable>كلمة المرور</v-lable>
                  <v-text-field filled v-model="user.password" placeholder="كلمة المرور" height="50"
                    :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'visibility' : 'visibility_off'"
                    @click:append="show1 = !show1" :rules="passwordRules" data-vv-name="password" required>
                  </v-text-field>
                  <div style="float:left;    position: relative;
    bottom: 23px;">


                    هل نسيت كلمه السر
                    <router-link to="/forgetpassword" text small color="red" style="font-weight:bold;font-size:18px">
                      اضغط هنا

                    </router-link>

                  </div>


                </v-flex>


                <v-flex xs12 md8 sm8>
                  <v-btn class="reg_owner_btn" @click="submit" color="red"
                    style="color:#fff;font-weight:bold;font-size:19px;" v-bind:class="{btn_active:signin}" height="60">
                    دخول</v-btn>

                </v-flex>

                <!-- </v-flex> -->

              </v-layout>
            </v-form>


            <v-card flat v-if="verfy_Dailog">
              <v-form v-model="active_form" ref="active_form">
                <v-container>

                  <v-layout row wrap>
                    <v-flex xs8>
                      <p style="color:#000;font-size:19px;font-weight:bold">تم ارسال رقم التاكيد برساله يرجئ التحقق</p>
                    </v-flex>

                    <v-flex xs12 md8 sm8>
                      <v-lable> رقم التاكيد</v-lable>
                      <v-text-field filled height="55" placeholder="ادخل رقم التاكيد" v-model="verfy_code" required
                        counter-value="4" type="number" :rules="verfyRules" autocomplete="off" class="mb-6">
                      </v-text-field>
                    </v-flex>




                    <v-flex xs6>
                      <v-btn color="success" @click="verfyAccount" style=";color:#fff;width:100%">
                        تفعيل
                      </v-btn>
                    </v-flex>

                  </v-layout>
                  <!-- <v-flex xs4>

                <v-btn color="red" @click="verfy_Dailog=false" style=";color:#fff;width:100%">
                  خروج
                </v-btn>


              </v-flex> -->

                </v-container>

              </v-form>
            </v-card>




          </div>





          <v-row>
            <v-text-field color="primary" loading disabled v-show="loading"></v-text-field>
          </v-row>

          <p style="color:red;text-align:center">{{this.errors}}</p>


        </v-card>

      </v-flex>


      <v-flex xs12 class="hidden-md-and-down" md5 sm5>
        <v-layout column class="search_component" style="height:100%;background-color: #F2C98A !important;">

          <div style="height: 100%;
    padding-top: 45px;

    position: fixed;
    bottom: 0;

    float: left;
    width: 40%;">


            <v-layout row wrap style="">

              <v-flex xs5></v-flex>

              <v-flex xs2>


                <v-img src="/assets/logo2.png" style="      height: 100%;
    width: 100%;"></v-img>




              </v-flex>


              <v-flex xs5></v-flex>

            </v-layout>
            <h2
              style="padding-top: 15px;width: 100%;text-align:center;font-weight:bold;font-size:30px;padding-right:15px;color:#986923;">
              منصه تكتاتي</h2>

            <v-row>
              <div
                style="width: 100%;text-align:center;color:#986923;font-size:20px;font-weight:bold;padding-top:10px;text-align: center;">
                حجوزات طبيه سياحيه مطاعم خدمات فنادق رياضه تصوير
              </div>
              <!-- <div  style="width: 100%;text-align:center;color:#986923;font-size:20px;font-weight:bold;padding-top:10px;text-align: center;padding:30px">
  منصة حجوزات هي منصة تمكن الزبائن من الحجز في الخدمات المسجلة لديها و تشمل خدمات كثيرة منها الحجوزات الطبية و الهندسية و حجوزات قاعات الاعراس و المؤتمرات و الخفلات و الندوات العلمية منصة حجوزات هي منصة تمكن الزبائن
</div> -->


            </v-row>
            <br>
            <br>


            <!-- <v-sheet class="mx-auto" style="background:none" dark="">
              <v-slide-group elevation="8" show-arrows dir="ltr">

                <v-slide-item v-for="item in images_com" :key="item.key" v-slot:default="{ active, toggle }" pa-5>
                  <v-card width="90" height="100"
                    style="box-shadow:none; margin-left:30px;margin-right:30px;background:none " dark pa-5>
                    <v-row justify="center">
                      <v-avatar height="100" width="100" color="white">
                        <v-img style="border:2px solid gray" :src="item.img" alt="John">
                        </v-img>
                      </v-avatar>
                    </v-row>
                    <v-spacer></v-spacer>
                    <v-row justify="center">
                      <v-card-title style="text-align:center">
                        شركة انجاز
                      </v-card-title>
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
              
            </v-sheet> -->





            <v-layout row wrap style="padding-top: 40px;">

              <v-flex xs2></v-flex>

              <v-flex xs8>


                <v-img src="/assets/3899624.png" style="with:100%;height:100%"></v-img>




              </v-flex>


              <v-flex xs2></v-flex>

            </v-layout>






          </div>



        </v-layout>
      </v-flex>
    </v-layout>





  </div>


</template>

<style scoped>
  .v-content {
    padding: 0px 0px 0px 0px !important;
  }
</style>


<script>
  import localstorageservice from "./localstorageservice";
  import {
    //ADMIN_ROLE,
    OWNER_ROLE,
    // USER_ROLE_GUEST
    //Delivery_ROLE
  } from "../constants.js";
  import {
    EventBus
  } from '../event-bus.js';

  // import last_itemVue from '../../last_item.vue';

  const axios = require('axios');
  export default {
    $_veeValidate: {
      validator: 'new',
    },
    name: 'app',
    data: () => ({
      active_status: [],
      show1: false,
      show2: true,
      show3: false,
      is_signIn: true,
      show4: false,
      password: 'Password',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('The email and password you entered don\'t match')
      },
      valid: true,
      login_info: [],


      img_name: '',
      index: '',
      img_cliced: -1,


      signup: true,
      imageUrl: '',
      imageFile: '',
      categories: [],
      token: '',
      verfy_Dailog: false,
      owners: {
        name: "",
        email: "",
        password: "",
        phone: "",
        company_name: "",
        category_id: ""


      },
      error_msg: '',
      signin: false,
      images: [{
          "image_url": ''
        },
        {
          "image_url": ''
        },
        {
          "image_url": ''
        },
        {
          "image_url": ''
        }
      ],

      owner_info: {
        id: '',
        name: '',
        session: '',
        email: '',
        role: '',
        photo: '',
        item_num: ''
      },

      loading: false,
      nameRules: [
        v => !!v || 'يجب ادخال الاسم',
        v => (v && v.length > 4) || 'يجب ان لايقل عن ٣ احرف',
      ],
      centerRules: [
        v => !!v || 'يجب ادخال اسم المركز',
        v => (v && v.length > 4) || 'يجب ان لايقل عن ٣ احرف',
      ],


      phoneRules: [
        (v) => !!v || 'يجب ادخال رقم الهاتف',
        (v) => v.length > 10 && v.length < 12 || 'يجب ادخال رقم هاتف صحيح',
        (v) => /^\d+$/.test(v) || 'يجب ادخال رقم هاتف صحيح',

      ],
      verfyRules: [
        (v) => !!v || 'يجب ادخال كود التفعيل',
        (v) => (v.length == 6) || ' يجب ان يكون كود التفعيل 6 ارقام',
        (v) => /^\d+$/.test(v) || 'ارقام فقط'

      ],





      passwordRules: [
        v => !!v || 'يجب ادخال الباسورد',
        //     (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
      ],
      emailRules: [
        //  v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'الايميل غير صحيح',
      ],

      errors: '',
      verfy_code: '',
      user: {
        phone: '',
        reg_dialog: false,
        password: '',
      },



      options: {
        isLoggingIn: true,
        shouldStayLoggedIn: true,
      },

    }),

    mounted() {
      // this.getCategory();
    },


    methods: {


      verfyAccount() {
        if (this.$refs.active_form.validate()) {

          this.$http({
              method: 'get',
              url: '/auth/v2/accountActivate/' + this.verfy_code,
              headers: {
                //  Authorization: 'Bearer '+this.token
              }

            }).then(response => {
              if (response.data.success == true) {
                // this.$swal('', "  شكرا تم التجسيل بنجاح يرجئ الانتظار قريبا لتفعيل الحساب من قبل اداره النظام",
                //   'success')

                this.$swal('', "شكرا تم التسجيل بنجاح ",
                  'success')
                this.login_info = response.data.data;
                this.$cookies.set('owner_info', this.owner_info);
                this.$user.set({
                  role: OWNER_ROLE
                });

                this.verfy_Dailog = false;
                EventBus.$emit('changeUserInfo', true);
                this.owner_info.role = this.$user.get().role;
                this.owner_info.id = this.login_info.id;
                this.owner_info.name = this.login_info.user_name;
                this.owner_info.phone = this.login_info.user_phone;
                this.owner_info.email = this.login_info.user_email;
                this.owner_info.photo = this.login_info.user_image;
                this.owner_info.item_num = this.login_info.item_num;
                this.$cookies.set('owner_info', this.owner_info);

                if (this.owner_info.item_num === 0) {

                  this.$router.push({
                    name: "AddNewItem"
                  })

                } else if (this.owner_info.item_num > 0) {

                  this.$router.push({
                    name: "OwnerDashbourd"
                  })

                }
                localstorageservice.setToken(this.login_info.token);


              }

            })
            .catch(error => {
              if (error.response.data.success == false) {
                this.$swal('', 'رمز التفعيل خطاء', 'error')
              }

            })


        }
      },
      getCategory() {

        var url = "/v2/Categories";
        axios.get(url).then(response => {
          this.categories = response.data.data;



        })
      },

      submit() {


        if (this.$refs.form_signin.validate()) {
          this.loading = true;

          axios({
              method: 'post',
              url: "/auth/v2/loginOwner",
              data: {
                user_phone: "964" + parseInt(this.user.phone),
                password: this.user.password,

              },
              headers: {

              }
            })
            .then(response => {

              if (response.data.data == 'User Unactive') {
                localstorageservice.setToken(response.data.token);
                this.verfy_Dailog = true;
                this.is_signIn = false;


              }


              this.login_info = response.data.data;
              if (this.login_info.user_role == "owner") {
                if (window.location.pathname !== '/owner') {
                  this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';

                } else {
                  this.$cookies.set('owner_info', this.owner_info);
                  this.$user.set({
                    role: OWNER_ROLE
                  });


                  EventBus.$emit('changeUserInfo', true);
                  this.owner_info.role = this.$user.get().role;
                  this.owner_info.id = this.login_info.id;
                  this.owner_info.name = this.login_info.user_name;
                  this.owner_info.phone = this.login_info.user_phone;
                  this.owner_info.email = this.login_info.user_email;
                  this.owner_info.photo = this.login_info.user_image;
                  this.owner_info.item_num = this.login_info.item_num;
                  this.$cookies.set('owner_info', this.owner_info);

                  if (this.owner_info.item_num == 0) {

                    this.$router.push({
                      name: "AddNewItem"
                    })

                  } else if (this.owner_info.item_num > 0) {

                    this.$router.push({
                      name: "OwnerDashbourd"
                    })

                  }

                  localstorageservice.setToken(this.login_info.token);



                  // this.$http({
                  //   method: 'get',
                  //   url: '/reservation/owner/dashboard',
                  //   headers: {

                  //   }

                  // }).then(response => {



                  //   response

                  //   this.$cookies.set('owner_info', this.owner_info);
                  //   this.$user.set({
                  //     role: OWNER_ROLE
                  //   });


                  //   this.owner_info.role = this.$user.get().role;
                  //   this.owner_info.id = this.login_info.id;
                  //   this.owner_info.name = this.login_info.user_name;
                  //   this.owner_info.phone = this.login_info.user_phone;
                  //   this.owner_info.email = this.login_info.user_email;
                  //   this.owner_info.photo = this.login_info.user_image;
                  //   this.$cookies.set('owner_info', this.owner_info);


                  //   this.$router.push({
                  //     name: "OwnerDashbourd"
                  //   })



                  // }).catch(error => {
                  //   error


                  //   this.$router.push({
                  //     name: "unactive"
                  //   });




                  //   error

                  // }).finally(d => {
                  //   d


                  // });

                }


              }
            })
            .catch(error => {


                if (error.response.data.data == 'User Unactive') {

                  localstorageservice.setToken(error.response.data.token);
                  this.verfy_Dailog = true;

                } else {
                  this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';


                }

                this.phonetoverfie = this.phone;
                if (error.response.data.code == 401) {
                  this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';
                }
                error
              }


            )

            .finally(aa => {
              aa,
              this.loading = false;

            });



        }

      },







    },
    components: {

    }
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>