<template>

  <v-app>



    <div class="hidden-md-and-down ">
      <!--Star Main header sign in sinup Media-->
      <v-toolbar dark height="30" color="#161616" class="sin_sinup_header">
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-md-and-down">
          <v-btn text>Tctate@info.tech.com</v-btn>
          <v-divider class="mx-2" vertical></v-divider>
          <v-btn text> 9647703406477 + </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!--End Main header sign in sinup Media-->
    </div>



    <!--Star Navigation Bar To Phone-->
    <div style="background-color:#242424;color:#fff" class="hidden-lg-and-up">
      <v-container>
        <v-layout>
          <v-flex xs2>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" style="color:#fff !important"></v-app-bar-nav-icon>
          </v-flex>
          <v-flex xs1>
          </v-flex>
          <v-flex xs2>
            <router-link to="/">
              <div style="width:33px;height:33px">
                <v-img src="/assets/logo2.png" style="with:100%;height:100%"></v-img>
              </div>
            </router-link>
          </v-flex>
          <v-flex xs4 pt-3 style="    position: relative;
    left: 11px;
    bottom: 10px;">

            <!-- <span style="margin-top:auto;margin-bottom:auto;color:#fff;font-weight:bold;font-size:20px">تكتاتي</span> -->
            <span style="margin-top:auto;margin-bottom:auto;color:#fff;font-weight:bold;font-size:20px">تكتاتي</span>


          </v-flex>





          <v-flex xs1>

          </v-flex>



          <v-flex xs2>



            <register_phone>

            </register_phone>




          </v-flex>




          <v-navigation-drawer v-model="drawer" app width="280" class="mx-auto" right>
            <v-list-item>

              <v-list-item-content>

                <v-list-item-title style="    font-family: 'cairo';">
                  تكتاتي
                </v-list-item-title>
                <!-- <v-list-item-subtitle>
                  subtext
                </v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list>
              <v-list-item to="/" style="padding:0px">




                <v-list-item-icon style="padding-left:3%">
                  <v-icon>as fa-home</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>الرئيسية</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


              <v-list-group prepend-icon="fas fa-border-all" append-icon="" style="padding:0px;    position: relative;
    left: 6%;">
                <template v-slot:activator style="padding:0px">
                  <v-list-item-title style="padding:0px">الاقسام</v-list-item-title>
                </template>


                <v-list-item :to="'/itemsByCatId/'+item.id" v-for="(item, index) in categies" :key="index">

                  <v-list-item-icon style="padding-left:3%">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>


              </v-list-group>





              <v-list-item style="padding:0px" to="/offers">


                <v-list-item-icon style="padding-left:3%">
                  <v-icon>fas fa-percent</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>العروض</v-list-item-title>
                </v-list-item-content>
              </v-list-item>



              <v-list-item style="padding:0px" to="/SeatTickets">


                <v-list-item-icon style="padding-left:3%">
                  <v-icon>fas fa-percent</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>الايفنتات و المباريات</v-list-item-title>
                </v-list-item-content>
              </v-list-item>






              <v-list-item style="padding:0px" to="/contactus">


                <v-list-item-icon style="padding-left:4%;">
                  <v-icon>fas fa-phone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>اتصل بنا</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


              <v-list-item style="padding:0px" to="/about">


                <v-list-item-icon style="padding-left:4%">
                  <v-icon>mdi-help-box</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>من نحن</v-list-item-title>
                </v-list-item-content>
              </v-list-item>



            </v-list>



          </v-navigation-drawer>
        </v-layout>


      </v-container>
    </div>
    <!--End Navigation Bar To Phone-->








    <v-toolbar class="hidden-md-and-down" dark :color="toolbarColor" height="80px">


      <v-container>
        <v-layout :class="toolbar_border" justify-center row wrap>
          <v-flex xs1 sm1 md1 xl1>

            <register></register>

          </v-flex>

          <v-flex xs9 style="    position: relative;right: 1%;">




            <v-toolbar-items>


              <v-tabs :background-color="toolbarColor" active-class="tap_active" color="red">

                <v-tab class="tabitem" style="color:red" to="/"> <span pr-4>

                    <i style="padding-left:6px" pl-4 class="fas fa-home"></i>
                  </span>&nbsp;&nbsp; الرئيسية </v-tab>


                <v-menu offset-y left="">
                  <template v-slot:activator="{ on }">
                    <v-tab class="tabitem" v-on="on">

                      <v-icon style="font-size:20px;color:#fff" right>fa-sm fa-border-all</v-icon> الاقسام


                    </v-tab>
                  </template>
                  <router-link to="">
                    <v-list>
                      <v-list-item :to="'/itemsByCatId/'+item.id" v-for="(item, index) in categies" :key="index">
                        <v-list-item-title>
                          <v-icon right="" style="padding-left:5px">{{item.icon}}</v-icon> {{ item.name }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </router-link>
                </v-menu>







                <v-tab class="tabitem" to="/offers"> <span pr-3>

                    <i style="padding-left:6px" pl-4 class="fas fa-percent"></i>
                  </span>&nbsp; اخر العروض </v-tab>

                <!-- <v-tab class="tabitem" to="/SeatTickets"> <span pr-3>

                    <i style="padding-left:6px" pl-4 class="far fa-futbol"></i>
                  </span>&nbsp;المباريات و الايفنتات</v-tab> -->





                <v-tab class="tabitem" to="/contactus"><span pr-3>

                    <i style="padding-left:6px" pl-4 class="fas fa-phone"></i>
                  </span>&nbsp; اتصل بنا </v-tab>
                <!--                   
                <v-tab class="tabitem" to="/about"> <span pr-3>
                    <v-icon style="font-size:23px;padding-left:10px">mdi-help-box</v-icon>
                  </span>&nbsp; من نحن

                </v-tab> -->

                <!-- <v-tab  @click="xx()"    class="tabitem" to="/mybooking"> <span pr-3>
                  
                    <i style="padding-left:6px" pl-4 class="fas fa-bookmark"></i>
                  </span>&nbsp; حجوزاتي
                  
                  </v-tab> -->

                <v-tab @click="xx()" class="tabitem" to="/mybooking">
                  <div @change="bookings_counts">
                    <div v-if="this.$cookies.get('user_info') !==null ">
                      <div v-if="this.$cookies.get('user_info').role =='user' ">
                        <!-- <v-badge> -->
                        <span pr-3>
                          <i style="padding-left:6px" pl-4 class="fas fa-bookmark"></i>
                        </span>&nbsp; حجوزاتي
                        <!-- </v-badge> -->

                      </div>


                    </div>

                  </div>
                </v-tab>




                <!-- <v-tab class="tabitem"  > <i class="fas fa-shopping-cart"></i> </v-tab> -->



                <!-- <v-tab class="tabitem" @click="xx()" style="padding-top:1%">


                  <div @change="bookings_counts">
                    <div v-if="this.$cookies.get('user_info') !==null ">
                      <div v-if="this.$cookies.get('user_info').role =='user' ">
                        <v-badge>

                          <template v-slot:badge>{{booking_count}}</template>


                          <v-icon>fas fa-shopping-cart</v-icon>
                        </v-badge>

                      </div>


                    </div>

                  </div>








                </v-tab> -->


              </v-tabs>

            </v-toolbar-items>
          </v-flex>


          <v-flex xs2>
            <v-spacer></v-spacer>

            <router-link :to="{ name: 'home'}">

              <v-row>
                <div class="div_logo_big">
                  <v-img src="/assets/logo2.png">

                  </v-img>
                </div>
                <span
                  style="margin-top:auto;margin-bottom:auto;margin-right:9px;color:#fff;font-weight:bold;font-size:24px">تكتاتي</span>
              </v-row>
            </router-link>

          </v-flex>




        </v-layout>
      </v-container>



    </v-toolbar>




    <router-view>

    </router-view>

    <footerx></footerx>







  </v-app>

</template>
<script type="application/javascript">
</script>


<script>
  import register from "./components/register.vue"
  import footerx from "./components/sub_components/footer.vue"
  import register_phone from "./components/register_phone.vue"
  import localstorageservice from "./localstorageservice";
  import {
    USER_ROLE_GUEST
  } from "./constants.js";

  import {
    EventBus
  } from './event-bus.js';





  export default {
    name: 'App',

    data: () => ({



      left: [{
        label: 'Home',
        icon: 'home',
        href: '#/home'
      }, {
        label: 'Animals',
        children: [{
            label: 'Elephant',
            href: '#/animals/elephant'
          }, {
            label: 'Lion',
            href: '#/animals/lion'
          }, {
            label: 'Bear',
            href: '#/animals/bear'
          }, {
            label: 'Eagle',
            href: '#/animals/eagle'
          }, {
            label: 'Wolf',
            href: '#/animals/wolf'
          }

        ]
      }],
      search_history: {
        cats_id: [

        ],
        sub_cats_id: [
            //   {
            //   sub_cats_id:'',
            //   date:'',
            //   priority:''
            // }
          ]

          ,
        item_name: ''
      },
      right: [{
          href: 'https://github.com/marcodpt/vue-tree-nav',
          icon: 'brands/github'
        }]

        ,




      open: ['public'],
      bookings_counts: false,
      files: {
        html: 'mdi-language-html5',
        js: 'mdi-nodejs',
        json: 'mdi-json',
        md: 'mdi-markdown',
        pdf: 'mdi-file-pdf',
        png: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
        xls: 'mdi-file-excel',
      },
      tree: [],
      itemsx: [

        {
          name: 'الاقسام',
          file: 'fas fa-border-all',
          children: [


          ],
        },

      ],




      icons: [
        'fab fa-facebook',
        'fab fa-twitter',
        'fab fa-google-plus',
        'fab fa-linkedin',
        'fab fa-instagram',
      ],
      urL: 'http://109.224.53.155',
      toolbar_style: 'home_toolbar',
      toolbarColor: "#242424",
      toolbar_border: '',
      drawer: false,

      categies: [],
      token: "",
      booking_count: localStorage.getItem('booking_count'),

      auths: localStorage.getItem('authenticat'),
      items: [{
          title: 'الرئيسية',
          to: '/',
          icon: 'fas fa-home'
        },
        {
          title: 'الاقسام',

          icon: 'fas fa-border-all',
          children: [{
            title: 'Contact us',
            to: '/contactus',
            icon: 'contact_support'
          }]
        },
        {
          title: 'العروض',
          to: '/',
          icon: 'fas fa-percent'
        },




        {
          title: 'اتصل بنا',
          to: '/',
          icon: 'fas fa-phone'
        },



        {
          title: 'من نحن',
          to: '/',
          icon: 'mdi-help-box'
        }
      ]


    }),
    methods: {


      getpublicIp() {

        function getIP(json) {
          document.write("My public IP address is: ", json.ip);
        }

        //          $(function() {
        //   $.getJSON("https://api.ipify.org?format=jsonp&callback=?",
        //     function(json) {
        //       alert(json.ip);
        //      // document.write("My public IP address is: ", json.ip);
        //     }
        //   );
        // });


        //          this.$http.get("https://api.ipify.org/?format=text").then(response => {
        //            alert('xxx');
        //                      console.log(response);
        //          var Ip = response.ip;
        //          console.log(response);
        //          console.log(Ip);
        //          alert(Ip);







        //       })

      },
      searchs_history() {
        if (this.$cookies.get('search_history') == 'no cookies' || this.$cookies.get('search_history') == null) {

          this.$cookies.set('search_history', this.search_history);
        }

      },
      logout() {
        this.$http({
            method: 'post',
            url: "/auth/v2/logout",
          })
          .then(response => {
            localstorageservice.clearToken();
            this.$cookies.remove("user_info");
            this.$user.set({
              role: USER_ROLE_GUEST
            });

            this.$router.push({
              name: 'ألرئيسية',
            });
          })
          .catch(error => {

            error
          }).finally(aa => {
            aa


          });
      },
      getCategorys() {


        this.$http.get("v2/Categories").then(response => {
          this.categies = response.data.data;

          var i = 0;
          for (i = 0; i <= this.categies.length; i++) {




            this.itemsx[0].children.push(

              {
                name: this.categies[i].name,

                file: this.categies[i].icon,
              }

            );


          }




        })
      },





      xx() {


        this.$router.push({
          name: 'mybooking'
        })

      },

      booking_counts() {

        // var url = 'reservation/user/search';

        // this.$http({
        //   method: 'get',
        //   url: url,
        //   headers: {

        //   }

        // }).then(response => {

        //   this.booking_count = response.data.meta.total;

        //   localStorage.setItem('booking_count', this.booking_count);


        // });

      }

    },

    components: {
      register,
      footerx,
      register_phone
    },
    created() {
      this.searchs_history();


      EventBus.$on('getUserReservationsCount', (xxx) => {
        xxx,
        this.bookings_counts = true,

        this.booking_counts();


      });
      this.getCategorys();
      EventBus.$on('authenticat', authenticat => {
        authenticat,
        this.auths = true;
        var userinfo = JSON.parse(localStorage.getItem('user_info'));
        this.user_name = userinfo.user_name;
        this.user_img = userinfo.user_image;

      });







    },


    mounted() {

      this.getCategorys();
      this.getpublicIp();


    }
  };
</script>