import Vue from 'vue'
import Router from 'vue-router'

//Admin Components
// import admin_dashbourd from "./components/admin/components/admin_dashbourd.vue"
// import Category from "./components/admin/components/category.vue"
// import subCategory from "./components/admin/components/subCategory.vue"
// import Owner from "./components/admin/components/owner.vue"
// import messages from "./components/admin/components/messages.vue"
// import lastCategory from "./components/admin/components/lastCategory.vue"
// import ownerBills from "./components/admin/components/bills.vue"
// import BillsByOwnerId from "./components/admin/components/ownersbills.vue"
// import users from "./components/admin/components/users.vue"
// import SubscriptionPackages from "./components/admin/components/SubscriptionPackages.vue"
// import Requestspackages from "./components/admin/components/Requestspackages.vue"
// import AdminAdvertising from "./components/admin/components/Advertising.vue"
// import AdvertisingTypes from "./components/admin/components/AdvertisingTypes.vue"

 import Login from "./components/admin/components/login.vue"


//AdvertisingTypes
//Owner Components
// import Additem from "./components/Owner/components/additem.vue"
// import AddNewItem from "./components/Owner/components/AddNewItem.vue"
// import OwnerDashbourd from "./components/Owner/components/OwnerDashbourd.vue"
// import OwnerItemRating from "./components/Owner/components/OwnerItemRating.vue"
// import request from "./components/Owner/components/request.vue"
// import Advertising from "./components/Owner/components/Advertising.vue"
// import unactive from "./components/Owner/components/unactives.vue"
 import LoginOwner from "./components/Owner/components/login.vue"
// import register_owner from "./components/Owner/components/register.vue"
// import forgetpassword from  "./components/Owner/components/forgetpassword.vue"
// import profile from  "./components/Owner/components/profile.vue"
// import mainInfo from  "./components/Owner/components/sub_components/AddItems/mainInfo.vue"
// import Bookingtimes from  "./components/Owner/components/sub_components/AddItems/Bookingtimes.vue"
// import AddressInfo from  "./components/Owner/components/sub_components/AddItems/AddressInfo.vue"
// import EditeDayOpen from  "./components/Owner/components/sub_components/EditeDayOpen.vue"
// import OwnerItemImage from  "./components/Owner/components/sub_components/UploudeImages.vue"
// import Address from  "./components/Owner/components/address.vue"
// import Subscriptionspackages from  "./components/Owner/components/subscriptionpackages.vue"
// import OwnerTasdidBill from  "./components/Owner/components/OwnerTasdidBill.vue"




import {
  USER_ROLE_GUEST,
  //USER_ROLE_REGISTERED,
  OWNER_ROLE,
  USER_ROLE,
  ADMIN_ROLE,
 // Delivery_ROLE

} from "./components/constants";



Vue.use(Router)

var currentRoute = window.location.pathname;

  export default new Router({
    scrollBehavior (to, from, savedPosition) {
      to ,from,savedPosition
        return new Promise((resolve, reject) => {
          reject
          setTimeout(() => {
            resolve({ x: 0, y: 0 })
          }, 300)
        })
      },


      
  routes: [


    {
      
      path: '/mybooking',
      name: 'mybooking',
      component:() => import(/*webpackChunkName:"mybooking" */'@/components/mybooking'),
      
      meta: {
        permissions: [

          {
              role:USER_ROLE_GUEST,
              access:false,
              redirect:"home"
          },

          {
            role:ADMIN_ROLE,
            access:false,
            redirect:"admin_dashbourd"
          },

          {
            role:OWNER_ROLE,
            access: false,
            redirect: "OwnerDashbourd"
          },

          {
            role: USER_ROLE,
            access: true,
            redirect: "mybooking"
          },




        ]
      }

    },
   
    {
      path: '/search/:cat_id/:gov_id/:tit',
      name: 'search',
      
      component:() => import(/*webpackChunkName:"search" */'@/components/search'),
      props: true,
      meta: {
        permissions: [

          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },

          {
            role: OWNER_ROLE,
            access: false,
            redirect: "OwnerDashbourd"
          },



        ]
      }

    }

    ,


    {
      path: "/LoginOwner",
      name: 'LoginOwner',
      component: LoginOwner,
      meta: {
        permissions: [{
            role:OWNER_ROLE,
            access: false,
           redirect: "OwnerDashbourd"
          },

          {
              role:ADMIN_ROLE,
              access: false,
             redirect: "admin_dashbourd"
            },

          {
            role:USER_ROLE_GUEST,
            access: true,
            redirect: "LoginOwner"
          }
        ]
      }

    },



    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        permissions: [{
            role:OWNER_ROLE,
            access: false,
           redirect: "OwnerDashbourd"
          },

          {
              role:ADMIN_ROLE,
              access: false,
             redirect: "admin_dashbourd"
            },

          {
            role:USER_ROLE_GUEST,
            access: true,
            redirect: "login"
          }
        ]
      }

    },

    {
      path: '/items',
      name: 'items',
  
      component:() => import(/*webpackChunkName:"items" */'@/components/items'),
      props: true,
      meta: {
        permissions: [

          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },

          {
            role: OWNER_ROLE,
            access: false,
            redirect: "OwnerDashbourd"
          },



        ]
      }


    },


    {
      path: '/itemsByCatId/:id',
      name: 'itemsByCatId',
      
      component:() => import(/*webpackChunkName:"items_byCat_id" */'@/components/items_byCat_id'),
      props: true,
      meta: {
        permissions: [

          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },

          {
            role: OWNER_ROLE,
            access: false,
            redirect: "OwnerDashbourd"
          },



        ]
      }


    },

    {
      path: '/item/:id',
      component:() => import(/*webpackChunkName:"item" */'@/components/item'),
      name: "item",
      meta: {
        permissions: [

          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },

          {
            role: OWNER_ROLE,
            access: false,
            redirect: "OwnerDashbourd"
          },



        ]
      }

    },
  
    {
      path: '/contactus',
      name: 'contactus',
      component:() => import(/*webpackChunkName:"Contactus" */'@/components/contactUs'),
      meta: {
        permissions: [

          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },

          {
            role: OWNER_ROLE,
            access: false,
            redirect: "OwnerDashbourd"
          },



        ]
      }


    },
    {
      path: '/offers',
      name: 'offers',
     
      component:() => import(/*webpackChunkName:"offers" */'@/components/offers'),
      meta: {
        permissions: [

          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },

          {
            role: OWNER_ROLE,
            access: false,
            redirect: "OwnerDashbourd"
          },



        ]
      }


    },

  //SeatTicket

   



 

    {
      path: '/ownerItems/:id',
      name: 'ownerItems',
      component:() => import(/*webpackChunkName:"ownerItems" */'@/components/ownerItems'),
      meta: {
     
      }


    },



    {
      path: '/about',
      name: 'about',
      component:() => import(/*webpackChunkName:"about" */'@/components/about'),
      
      meta: {
        permissions: [

          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },

          {
            role: OWNER_ROLE,
            access: false,
            redirect: "OwnerDashbourd"
          },



        ]
      }

    },


  currentRoute=="/"? {
    path: '/',
    name: 'home',
    component:() => import(/*webpackChunkName:"home" */'@/components/home'),
 


  }:

  currentRoute=="/owner"?  {
      path: '/',
      
      component:() => import(/*webpackChunkName:"OwnerDashbourd" */'@/components/Owner/components/OwnerDashbourd'),
  
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "home"
          },
          {
            role: ADMIN_ROLE,
            access:true,
            redirect:"home"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "OwnerDashbourd"
          }


        ]
      }

    }: {
    

      path: '/',
    
      component:() => import(/*webpackChunkName:"admin_dashbourd" */'./components/admin/components/admin_dashbourd'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "login"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "admin_dashbourd"
          },

        ]
      }

    },

    {
      
      
      path:'/register_owner',
      name:'register_owner',
      component:() => import(/*webpackChunkName:"register_owner" */'./components/Owner/components/register'),

    },
{
    path:'/forgetpassword',
      name:'forgetpassword',

      component:() => import(/*webpackChunkName:"forgetpassword" */'@/components/Owner/components/forgetpassword'),
    },

    



    {
      path: "/LoginOwner",
      name: 'LoginOwner',
      component:() => import(/*webpackChunkName:"LoginOwner" */'./components/Owner/components/login'),

    },



    {
      path: "/login",
      name: "login",
      component:() => import(/*webpackChunkName:"Login" */'./components/Owner/components/login'),


      meta: {
        permissions: [{
            role:OWNER_ROLE,
            access: false,
           redirect: "OwnerDashbourd"
          },

          {
              role:ADMIN_ROLE,
              access: false,
             redirect: "admin_dashbourd"
            },

          {
            role:USER_ROLE_GUEST,
            access: true,
            redirect: "login"
          }
        ]
      }

    },


    {
      path: '/unactive',
      name: 'unactive',
      component:() => import(/*webpackChunkName:"unactive" */'./components/Owner/components/unactives'),

      meta: {
       
      }


    },
    {
      path: "/profile",
      name: "profile",
      component:() => import(/*webpackChunkName:"profile" */'./components/Owner/components/profile'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "profile"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "login"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          }
        ]
      }

    },







    // {
    //   path: "/mainInfo",
    //   name: "mainInfo",
    //   component:() => import(/*webpackChunkName:"mainInfo" */'./components/Owner/components/mainInfo'),
    //   meta: {
    //     permissions: [{
    //         role: USER_ROLE_GUEST,
    //         access: false,
    //         redirect: "LoginOwner"
    //       },
    //       {
    //         role: OWNER_ROLE,
    //         access: true,
    //         redirect: "profile"
    //       },
    //       {
    //         role: ADMIN_ROLE,
    //         access: false,
    //         redirect: "login"
    //       },
    //       {
    //         role: USER_ROLE,
    //         access: false,
    //         redirect: "home"
    //       }
    //     ]
    //   }

    // },



    {
      path: "/EditeDayOpen/:id",
      name: "EditeDayOpen",
      component:() => import(/*webpackChunkName:"component" */'./components/Owner/components/sub_components/EditeDayOpen'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "profile"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "login"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          }
        ]
      }

    },


    {
      path: "/OwnerItemImage/:id",
      name: "OwnerItemImage",
      component:() => import(/*webpackChunkName:"OwnerItemImage" */'./components/Owner/components/sub_components/UploudeImages'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "profile"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "login"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          }
        ]
      }

    },

    
     {
      path: "/address",
      name: "Address",
      component:() => import(/*webpackChunkName:"Address" */'./components/Owner/components/address'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "profile"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "login"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          }
        ]
      }

    },

    


    {
      path: "/OwnerTasdidBill",
      name: "OwnerTasdidBill",
      component:() => import(/*webpackChunkName:"OwnerTasdidBill" */'./components/Owner/components/OwnerTasdidBill'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "profile"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "login"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          }
        ]
      }

    },
    {
      path: "/Subscriptionpackages",
      component:() => import(/*webpackChunkName:"Subscriptionspackages" */'./components/Owner/components/subscriptionpackages'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "profile"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "login"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          }
        ]
      }

    },




    
    


    

    // {
    //   path: "/Bookingtimes",
    //   name: "Bookingtimes",
    //   component:() => import(/*webpackChunkName:"Bookingtimes" */'./components/Owner/components/Bookingtimes'),
    //   component:Bookingtimes,
    //   meta: {
    //     permissions: [{
    //         role: USER_ROLE_GUEST,
    //         access: false,
    //         redirect: "LoginOwner"
    //       },
    //       {
    //         role: OWNER_ROLE,
    //         access: true,
    //         redirect: "profile"
    //       },
    //       {
    //         role: ADMIN_ROLE,
    //         access: false,
    //         redirect: "login"
    //       },
    //       {
    //         role: USER_ROLE,
    //         access: false,
    //         redirect: "home"
    //       }
    //     ]
    //   }

    // },
    


    // {
    //   path: "/AddressInfo",
    //   name: "AddressInfo",
    //   component:AddressInfo,
    //   meta: {
    //     permissions: [{
    //         role: USER_ROLE_GUEST,
    //         access: false,
    //         redirect: "LoginOwner"
    //       },
    //       {
    //         role: OWNER_ROLE,
    //         access: true,
    //         redirect: "profile"
    //       },
    //       {
    //         role: ADMIN_ROLE,
    //         access: false,
    //         redirect: "login"
    //       },
    //       {
    //         role: USER_ROLE,
    //         access: false,
    //         redirect: "home"
    //       }
    //     ]
    //   }

    // },

    

    {
      path: "/additem",
      name: "additem",

      component:() => import(/*webpackChunkName:"Additem" */'./components/Owner/components/additem'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "request"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          }
        ]
      }

    },




    {
      path: "/AddNewItem/:id",
      name: "AddNewItemEdite",
      component:() => import(/*webpackChunkName:"AddNewItemEdite" */'./components/Owner/components/AddNewItem'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "request"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          }
        ]
      }

    },

    {
      path: "/AddNewItem",
      name: "AddNewItem",
      component:() => import(/*webpackChunkName:"AddNewItem" */'./components/Owner/components/AddNewItem'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "AddNewItem"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          }
        ]
      }

    },
    


    {
      path: '/Advertising',
      name: 'Advertising',
      component:() => import(/*webpackChunkName:"Advertising" */'./components/Owner/components/Advertising'),
      
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "Advertising"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },
          {
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "home"
          }
        ]
      }

    },


    {
      path: '/request',
      name: 'request',
      component:() => import(/*webpackChunkName:"request" */'./components/Owner/components/request'),
      
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "request"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },
          {
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "home"
          }
        ]
      }

    },

    {
      path: '/OwnerItemRating',
      name: 'OwnerItemRating',
      component:() => import(/*webpackChunkName:"OwnerItemRating" */'./components/Owner/components/OwnerItemRating'),
      
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "LoginOwner"
          },
          {
            role: OWNER_ROLE,
            access: true,
            redirect: "OwnerItemRating"
          },
          {
            role: ADMIN_ROLE,
            access: false,
            redirect: "admin_dashbourd"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          }
        ]
      }

    },











    ///////////////////-----------------------Admain Route---------------------///////////////////







  





    {

      path: '/category',
      name: 'category',
      component:() => import(/*webpackChunkName:"Category" */'./components/admin/components/category'),
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "login"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "OwnerDashbourd"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "merchants"
          },

          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },


        ]
      }

    },

    {
      path: '/subCategory/:id/:name',
      name: 'subCategory',
      component:() => import(/*webpackChunkName:"subCategory" */'./components/admin/components/subCategory'),
      props: true,
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "login"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "merchants"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },

        ]
      }

    },


    {
      path: '/lastCategory/:id/:name',
      name: 'lastCategory',
      component:() => import(/*webpackChunkName:"lastCategory" */'./components/admin/components/lastCategory'),
      props: true,
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "login"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "merchants"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },

        ]
      }

    },




{
  path: '/BillsByOwnerId/:id',
  name: 'BillsByOwnerId',
  component:() => import(/*webpackChunkName:"BillsByOwnerId" */'./components/admin/components/ownersbills'),
  
  props: true,
  meta: {
    permissions: [{
        role: USER_ROLE_GUEST,
        access: false,
        redirect: "login"
      },
      {
        role: OWNER_ROLE,
        access: false,
        redirect: "login"
      },

      {
        role: ADMIN_ROLE,
        access: true,
        redirect: "merchants"
      },
      {
        role: USER_ROLE,
        access: false,
        redirect: "home"
      },

    ]
  }

},
    {
      path: '/ownerBills',
      name: 'ownerBills',
      component:() => import(/*webpackChunkName:"ownerBills" */'./components/admin/components/bills'),
  
      props:false,
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "login"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "merchants"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },

        ]
      }

    },



    {
      path: '/messages',
      name: 'messages',
      component:() => import(/*webpackChunkName:"messages" */'./components/admin/components/messages'),
      props: false,
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "login"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "messages"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },

        ]
      }

    },




    {
      path: '/owner',
      name: 'owner',
      component:() => import(/*webpackChunkName:"Owner" */'./components/admin/components/owner'),
      props: false,
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "login"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "merchants"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },

        ]
      }

    },



    {
      path: '/users',
      name: 'users',
      component:() => import(/*webpackChunkName:"users" */'./components/admin/components/users'),
      props: false,
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "home"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "users"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },

        ]
      }

    },



    {
      path: '/Requestspackages',
      name: 'Requestspackages',
      component:() => import(/*webpackChunkName:"Requestspackages" */'./components/admin/components/Requestspackages'),
      props: false,
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "home"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "Requestspackages"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },

        ]
      }

    },



    {
      path: '/AdminAdvertising',
      name: 'AdminAdvertising',
      component:() => import(/*webpackChunkName:"AdminAdvertising" */'./components/admin/components/Advertising'),

      props: false,
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "home"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "AdminAdvertising"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },

        ]
      }

    },
    {
      path: '/AdvertisingTypes',
      name: 'AdvertisingTypes',
      component:() => import(/*webpackChunkName:"AdvertisingTypes" */'./components/admin/components/AdvertisingTypes'),

      props: false,
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "home"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "AdvertisingTypes"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },

        ]
      }

    },
    {
      path: '/SubscriptionsPackages',
      name: 'SubscriptionPackages',
      component:() => import(/*webpackChunkName:"SubscriptionPackages" */'./components/admin/components/SubscriptionPackages'),
      props: false,
      meta: {
        permissions: [{
            role: USER_ROLE_GUEST,
            access: false,
            redirect: "home"
          },
          {
            role: OWNER_ROLE,
            access: false,
            redirect: "login"
          },

          {
            role: ADMIN_ROLE,
            access: true,
            redirect: "SubscriptionPackages"
          },
          {
            role: USER_ROLE,
            access: false,
            redirect: "home"
          },

        ]
      }

    },

    








   
    { path: "*",redirect:'/'}


  ]
});





