<template>

  <div>



    <div class="login-dark" v-if="!otpDialog">
      <v-form ref="form" v-model="valid">
        <v-row>
          <div style="width:85px;height:90px;position: relative; right: 35%;">
            <v-img src="/assets/logo2.png" style="with:100%;height:100%"></v-img>
          </div>
        </v-row>

        <br>

        <v-row class="mb-6" justify="center">

          <h3 style="color:black">تسجيل الدخول </h3>

        </v-row>




        <div class="" style="color:red;font-size:10px">
          {{error_msg}}
        </div>
        <v-text-field v-model="user.phone" label="رقم الهاتف" data-vv-name="name" type="number" :rules="phoneRules"
          required></v-text-field>


        <v-text-field v-model="user.password" label="كلمة المرور" 
          :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'visibility' : 'visibility_off'"
          @click:append="show1 = !show1" :rules="passwordRules" data-vv-name="password" required></v-text-field>







        <v-row justify="center">
          <v-btn class="btn btn-primary btn-block" color="red" @click="submit" style=";color:#fff;width:100%">
            دخول</v-btn>

        </v-row>
        <v-row>
          <v-text-field color="primary" loading disabled v-show="loading"></v-text-field>
        </v-row>

        <p style="color:red;text-align:center">{{this.errors}}</p>
      </v-form>

    </div>


     <div class="login-dark" v-else-if="otpDialog">
      <v-form ref="Otpsform" v-model="Otpsvalid">
        <v-row>
          <div style="width:85px;height:90px;position: relative; right: 35%;">
            <v-img src="/assets/logo1.png" style="with:100%;height:100%"></v-img>
          </div>
        </v-row>

        <br>

        <v-row class="mb-6" justify="center">

          <h3 style="color:black">تسجيل الدخول </h3>

        </v-row>




        <div class="" style="color:red;font-size:10px">
          {{error_msg}}
        </div>
        <v-text-field v-model="user.otpNumber" label="رمز التاكيد" data-vv-name="name" type="number" :rules="verfyRules"
          required></v-text-field>


        <!-- <v-text-field v-model="user.password" label="كلمة المرور" 
          :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'visibility' : 'visibility_off'"
          @click:append="show1 = !show1" :rules="passwordRules" data-vv-name="password" required></v-text-field>


 -->




        <v-row justify="center">
          <v-btn class="btn btn-primary btn-block" color="red" @click="Otpsubmit()" style=";color:#fff;width:100%">
            دخول</v-btn>

        </v-row>
        <v-row>
          <v-text-field color="primary" loading disabled v-show="loading"></v-text-field>
        </v-row>

        <p style="color:red;text-align:center">{{this.errors}}</p>
      </v-form>

    </div>




  </div>


</template>




<script>
  import localstorageservice from "./localstorageservice";
  import {
    ADMIN_ROLE,
    // OWNER_ROLE,
    // Delivery_ROLE
  } from "../constants.js";


  export default {
    $_veeValidate: {
      validator: 'new',
    },
    name: 'app',
    data: () => ({
      active_status: [],
      valid: true,
      show1:false,
      login_info: [],
        verfyRules: [
        (v) => !!v || 'يجب ادخال كود التفعيل',
        (v) => (v.length == 6) || ' يجب ان يكون كود التفعيل 6 ارقام',
        (v) => /^\d+$/.test(v) || 'ارقام فقط'

      ],
      error_msg: '',

      owner_info: {
        id: '',
        name: '',
        session: '',
        email: '',
        role: '',
        photo: ''
      },
      admin_info: {
        id: '',
        name: '',
        session: '',
        email: '',
        role: '',
        photo: ''
      },
      loading: false,
      nameRules: [
        v => !!v || 'Name is requirsed',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      phoneRules: [
        (v) => !!v || 'يجب ادخال رقم الهاتف',
        (v) => v.length > 10 && v.length < 12 || 'يجب ادخال رقم هاتف صحيح',

      ],
      passwordRules: [
        v => !!v || 'كلمة السر مطلبة',
        v => (v && v.length <= 10) || 'Password must be less than 10 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],

      errors: '',
      user: {
        phone: '',
        reg_dialog: false,
        password: '',
        otpNumber:''
      },



      options: {
        isLoggingIn: true,
        shouldStayLoggedIn: true,
      },
      otpDialog:false,

    }),

    // mounted() {},


    methods: {

      submit() {


        if (this.$refs.form.validate()) {
          this.loading = true;

          this.$http({
              method: 'post',
              url: "/auth/v2/loginOpertion",
              data: {
                user_phone: "964" + parseInt(this.user.phone),
                password: this.user.password,
              }
            })
            .then(response => {
response
              if (window.location.pathname !== '/admin') {


                  this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';

                } 
                else
                {
this.otpDialog=true;
                }


              // this.login_info = response.data.data;


              // if (this.login_info.user_role == "admin") {
              //   if (window.location.pathname !== '/admin') {


              //     this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';

              //   } else {
              //     this.$user.set({
              //       role: ADMIN_ROLE
              //     });

              //     this.admin_info.role = this.$user.get().role;
              //     this.admin_info.name = this.login_info.user_name;
              //     this.admin_info.phone = this.login_info.user_phone;
              //     this.admin_info.email = this.login_info.user_email;
              //     this.admin_info.photo = this.login_info.user_image;

              //     localstorageservice.setToken(this.login_info.token);
              //     this.$cookies.set('admin_info', this.admin_info);

              //     this.$router.push({
              //       name: "admin_dashbourd"
              //     });

              //   }

              // } 


            })
            .catch(error => {
              this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';
              //  this.phonetoverfie = this.phone;
              if (error.response.data.code == 401) {
                this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';
              }
              error
            }).finally(aa => {
              aa,
              this.loading = false;

            });



        }

      },


   Otpsubmit() {


        if (this.$refs.Otpsform.validate()) {
          this.loading = true;

         this.$http({
              method: 'post',
              url: "/auth/v2/OperationLoginOtp",
              data: {
                user_phone: "964" + parseInt(this.user.phone),
                password: this.user.password,
                otpNumber:this.user.otpNumber
              }
            })
            .then(response => {
///this.otpDialog=true;
              this.login_info = response.data.data;


              if (this.login_info.user_role == "admin") {
                if (window.location.pathname !== '/admin') {


                  this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';

                } else {
                  this.$user.set({
                    role: ADMIN_ROLE
                  });

                  this.admin_info.role = this.$user.get().role;
                  this.admin_info.name = this.login_info.user_name;
                  this.admin_info.phone = this.login_info.user_phone;
                  this.admin_info.email = this.login_info.user_email;
                  this.admin_info.photo = this.login_info.user_image;

                  localstorageservice.setToken(this.login_info.token);
                  this.$cookies.set('admin_info', this.admin_info);

                  this.$router.push({
                    name: "admin_dashbourd"
                  });

                }

              } 


            })
            .catch(error => {
              this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';
              //  this.phonetoverfie = this.phone;
              if (error.response.data.code == 401) {
                this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';
              }
              error
            }).finally(aa => {
              aa,
              this.loading = false;

            });



        }

      },
      checkactive() {
        // alert('stsr');



      }


    },
    components: {

    }
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>