<template>

  <v-app style="background:#f5f5f5" height="100px">

    <div v-if="this.$cookies.get('owner_info') !==null ">
      <div v-if="this.$cookies.get('owner_info').role =='owner' && this.$cookies.get('owner_info').active !=='no'  ">
        <v-navigation-drawer v-model="drawer" app width="300" clipped class="mx-auto" color="#035aa6" dark right>


          <!-- user info ui -->
          <v-list style="margin-top:27px" nav class="py-0">


            <div class="d-flex justify-center mb-6" style="font-size:22px;color:gary;font-weight: 700;">

              <v-avatar size="110">

                <v-img src="/assets/logo2.png"></v-img>
              </v-avatar>
            </div>

            <div class="d-flex justify-center mb-6" style="font-size:22px;color:gary;font-weight: 700;">
              <div>
                منصه تكتاتي

              </div>

              <!-- <div>
              {{getname(admin_info.user_email)}}
            </div> -->

            </div>







            <hr>
          </v-list>



          <!--Star Owner Sidbar-->
          <div>





            <v-list style=" font-family: 'cairo' ">

              <v-list-item style=" font-family: 'cairo'" to="/AddNewItem"
                v-if="(this.$cookies.get('owner_info').item_num==0 && !add_item) && owner.item_count == 0">
                <v-list-item-icon>
                  <v-icon style="margin-left:10px">fas fa-bookmark</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>اضافه معلومات المركز</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/">
                <v-list-item-icon>
                  <v-icon style="margin-left:10px">mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>ألرئيسية </v-list-item-title>
                </v-list-item-content>
              </v-list-item>



              <v-list-item link to="/additem">
                <v-list-item-icon>
                  <v-icon style="margin-left:10px">fas fa-bookmark</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>الخدمات</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


              <v-list-item link to="/request">
                <v-list-item-icon>
                  <v-icon style="margin-left:10px">fas fa-envelope</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title> الحجوزات </v-list-item-title>
                </v-list-item-content>
              </v-list-item>



              <v-list-item link to="/address"
                v-if="$cookies.get('owner_info').item_num > 0  || add_item ||owner.item_count >0">
                <v-list-item-icon>
                  <v-icon style="margin-left:10px">fas fa-map-marker-alt</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>الموقع</v-list-item-title>
                </v-list-item-content>
              </v-list-item>



              <v-list-item link to="/OwnerItemRating">
                <v-list-item-icon>
                  <v-icon style="margin-left:10px">fas fa-star</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>التقييم</v-list-item-title>
                </v-list-item-content>
              </v-list-item>



              <v-list-item link to="/Advertising">
                <v-list-item-icon>
                  <v-icon style="margin-left:10px">fas fa-bullhorn</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>الاعلانات</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


              <v-list-item link to="/Subscriptionpackages" v-if="owner.user.status.id==1">
                <v-list-item-icon>
                  <v-icon style="margin-left:10px">fas fa-money-check-alt</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>حزم الاشتراك</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


              <v-list-item link to="/OwnerTasdidBill">
                <v-list-item-icon>
                  <v-icon style="margin-left:10px">fas fa-money-bill</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>فواتير تسديد</v-list-item-title>
                </v-list-item-content>
              </v-list-item>








              <v-list-item @click="logout" to="/LoginOwner">
                <v-list-item-icon>
                  <v-icon style="margin-left:10px">fas fa-sign-out-alt</v-icon>

                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>تسجيل الخروج</v-list-item-title>
                </v-list-item-content>
              </v-list-item>





            </v-list>


          </div>
          <!--End Owner Sidbar-->

































        </v-navigation-drawer>



        <v-toolbar color="#13334c" dark fixed height="">

          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <v-toolbar-title style="padding: 14px;    padding-left: 6px;">



            <v-badge avatar d overlap>
              <template v-slot:badge>
                <div>
                  <v-avatar v-if="show_notification==true">

                    <span>{{now_not_num}}</span>
                  </v-avatar>

                  <v-avatar v-else>
                    <span>0</span>
                  </v-avatar>
                </div>
              </template>

              <v-avatar size="40">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">

                    <v-btn color="#fff" dark v-bind="attrs" icon v-on="on" @click="show_not()">
                      <v-icon>fas fa-bell</v-icon>

                    </v-btn>
                  </template>

                  <v-card width="488px">
                    <v-list two-line>

                      <v-subheader>
                        الحجوزات
                      </v-subheader>
                      <template>


                        <div v-for="item in last_booking" :key="item">
                          <!-- <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>
                     -->
                          <v-list-item style="padding:0px" to="/request">
                            <v-list-item-avatar>
                              <!-- <img v-if="item.user.image!==null" :src="UrL+'/images/'+item.user.image.image_url "> -->
                              <img src="https://www.shareicon.net/data/512x512/2016/08/05/806962_user_512x512.png">
                            </v-list-item-avatar>
                            <v-list-item-content pr-3>
                              <v-list-item-title style="padding-right:8px;">{{item.notification_title}}
                              </v-list-item-title>
                              <v-list-item-subtitle style="padding-right:8px;">
                                {{item.notification_body}}
                                <!-- {{item.reservation_end_date }} | {{formatAMPM(item.reservation_from_time)}} | -->

                                <!-- 
                                <v-chip small :color="item.status.status_color">

                                  <span style="color:#fff;font-size:10px"> {{item.status.status_name_ar}}

                                    <span>
                                      <v-icon left small> {{item.status.status_icon}}</v-icon>
                                    </span>
                                  </span>
                                </v-chip> -->


                              </v-list-item-subtitle>
                            </v-list-item-content>

                          </v-list-item>
                          <!-- 
                          <v-divider :key="item.user.full_name" inset></v-divider> -->
                        </div>
                      </template>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-avatar>

            </v-badge>




            <router-link to="/profile">

              <span style="color:#fff;padding-right:16px;"> | {{owner.user.full_name}} </span>
            </router-link>

            <v-badge bordered bottom color="green" dot offset-x="10" offset-y="10" style="padding-right:9px;">
              <v-avatar size="40">
                <v-img :src="images[0]">
                </v-img>
              </v-avatar>
            </v-badge>

          </v-toolbar-title>


        </v-toolbar>


      </div>
    </div>
    <v-content v-if="drawer">

      <span style="background:red">
        <router-view />
      </span>


    </v-content>
    <v-container v-else>

      <span style="background:red">
        <router-view />
      </span>


    </v-container>

  </v-app>




</template>


<style>
  .isActive {
    background-color: red !important;
  }

  .errorClass {
    background-color: blue !important;
  }

  .state_color {
    color: #fff;
  }
</style>
<script>
  import {
    USER_ROLE_GUEST,
    OWNER_ROLE
  } from "./constants";
  import {
    EventBus
  } from './event-bus.js';

  import localstorageservice from "./localstorageservice";

  export default {
    name: 'App',
    data() {
      return {
        dialog: true,
        show_notification: true,
        not_num: 0,
        images: [],
        now_not_num: '',
        num_last_res: '',
        x:'',
        urL: "https://api.ahjez-ely.com",
        owner: {
          name: "",
          user: {
            full_name: ''

          },
          email: "",
          now_not_num: 0,
          password: "",
          phone: "",
          company_name: "",
          category_id: "",
          owner_barnd_name: ""


        },

        // urL: 'http://109.224.53.155',
        username: [],
        auths: false,
        drawer: true,
        add_item: false,
        session: '',
        cases: [],
        reg_dialog: false,
        case_number: '',
        //  user_img: '',
        token: '',
        user_role: '',
        admin_info: {
          user_name: '',
          user_email: '',
          user_image: ''
        },
        userImg: 'https://cdn.vuetifyjs.com/images/john.jpg'

      }
    },
    computed: {

    },
    created() {
      
      EventBus.$on('additemsnew', (from) => {
        from,

        this.add_item = true;

      });

      EventBus.$on('changeUserInfo', (from) => {
        from,
        this.GetOwnerInfo();
        // this.OwnerResCount();

      });




      // this.OwnerResCount();
      // //this.timer = setInterval(this.OwnerResCount(), 3000)
      // this.interval = setInterval(() => this.OwnerResCount(), 10000);


    },


    watch: {



    },

    mounted() {
     
      if (this.$cookies.get('owner_info') !== 'no cookies' || this.$cookies.get('owner_info') !== null)

      {
        if (this.$cookies.get('owner_info').role == OWNER_ROLE)

        {
          ///  this.OwnerResCount();
          //this.timer = setInterval(this.OwnerResCount(), 3000)
          this.GetOwnerInfo();
          this. GetOwnerInfo();
          this.interval = setInterval(() => this.OwnerResCount(), 100000);

          this.GetOwnerInfo();
          this.last_booking();
           this.last_booking();
          this.$user.set({
            role: 'owner'
          });




        } else {
          this.$user.set({
            role: USER_ROLE_GUEST
          });

          this.$cookies.set('owner_info'.role, USER_ROLE_GUEST)
          this.$router.push({
            name: "LoginOwner"
          });
        }
      }




    },


    methods: {


      notif_info() {

        var notifiction_info = {
          'phone_number': this.$cookies.get('owner_info').phone,
          'res_number': this.not_num
        }

        localStorage.setItem('notifiction_info', JSON.stringify(notifiction_info));
        // var tt = localStorage.getItem('notifiction_info');

        // console.log('retrievedObject: ', JSON.parse(tt));



      },
      GetOwnerInfo() {
        this.$http({
          method: 'get',
          url: 'owner/v2/OwnerInfo',
          headers: {

          }

        }).then(response => {

          this.owner = response.data.data;

          if (this.owner.user.image !== null) {


            // this.img_id = this.owner.user.image.id;
            this.owner.user.image !== null && this.owner.user.image !== null ? this.images[0] = this
              .Url + "/images/" + this.owner.user.image.image_url :
              this
              .images[0] =
              "https://banner2.cleanpng.com/20180526/kuv/kisspng-user-avatar-playerunknown-s-battlegrounds-cryptocu-5b090fc8c0a8e4.6350091115273205207892.jpg";



          } else {
            this
              .images[0] =
              "https://banner2.cleanpng.com/20180526/kuv/kisspng-user-avatar-playerunknown-s-battlegrounds-cryptocu-5b090fc8c0a8e4.6350091115273205207892.jpg";

          }

        }).catch(error => {
          error

        }).finally(s => {
          s

        })

      },

      // OwnerResCount() {
      //   var url = '/owner/v2/OwnerResCount';
      //   this.$http({
      //     method: 'get',
      //     url: url,
      //   }).then(response => {

      //     this.not_num = response.data.data;

      //     var not_info = localStorage.getItem('notifiction_info');
      //     //console.log(not_info);
      //     if (not_info == null) {
      //       this.now_not_num = 0;
      //       this.notif_info();


      //     } else {

      //       var local_res_num = JSON.parse(not_info).res_number;


      //       if (this.not_num > local_res_num) {

      //         this.show_notification = true;
      //         this.now_not_num = this.not_num - local_res_num;

      //         this.last_booking();


      //       } else {

      //         this.now_not_num = 0;

      //       }


      //     }


      //     // if (response.data.data > this.not_num &&
      //     //   this.show_notification == false
      //     // ) {
      //     //   this.show_notification = true;
      //     //   return this.not_num = response.data.data - this.not_num;
      //     // } else {

      //     //   this.show_notification == false
      //     //   return this.not_num = this.not_num - this.not_num;
      //     // }

      //     // alert(this.ResCount);
      //   }).catch(error => {
      //     error
      //     ///   this.$swal('خطاء', "خطاء بالاتصال", 'error')
      //   }).finally();


      // },
      show_not() {
        this.show_notification = false;
        // this.num_last_res = this.now_not_num;
        // this.now_not_num = 0;

        // this.notif_info();


      },

      last_booking() {
        var url = '/notifications/reservations/owner/get?page=1';
        this.$http({
          method: 'get',
          url: url,
        }).then(response => {

          this.last_booking = response.data.data.data.slice(0, 4);
          this.now_not_num = this.last_booking.length;

        }).catch(error => {
          error

        }).finally();
      },


      formatAMPM(date) {
        if (typeof date === "string") {
          let [hours, minutes] = date.split(":");
          let ampm = "ص";

          if (Number(hours) > 12) {
            hours = Number(hours) - 12;
            ampm = "م";
          }

          return `${hours}:${minutes} ${ampm}`;

        } else if (date instanceof Date) {
          let hours = date.getHours();
          let minutes = date.getMinutes();

          let ampm = hours >= 12 ? 'م' : "AM";

          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes;

          let strTime = hours + ":" + minutes + " " + ampm;

          return strTime;
        }

        return date;
      },

      getname(name) {
        return name.split("@")[0];
      },

      initialize() {

      },


      logout() {

        this.$http({
            method: 'post',
            url: "/auth/v2/logout",
          })
          .then(response => {
            response,
            this.x='asda';
            localstorageservice.clearToken();
            this.$cookies.remove("owner_info");
            this.$user.set({
              role: USER_ROLE_GUEST
            });
            // this.$router.push({
            //   name: "LoginOwner"
            // });
          })
          location.reload()
          .catch(error => {

            error
          }).finally(aa => {
            aa


          });












      }
    }
  }
</script>