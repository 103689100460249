<template>
    <div>


        <div class="d-inline   accent-4 white--text">

            <div v-if="this.$cookies.get('user_info') !==null ">

                <div v-if="this.$cookies.get('user_info').role =='user' ">



                    <div class="d-inline   accent-4 white--text">

                        <v-menu offset-y left>
                            <template v-slot:activator="{ on }">
                                <v-btn small color="warning" fab dark v-on="on">
                                    <v-avatar size="50">
                                        <img :src="user_imgx">
                                    </v-avatar>
                                </v-btn>
                            </template>
                            <v-list>


                                <!-- <v-list-item>
                                    <v-list-item-title> <i class="fas fa-user"></i> البروفايل</v-list-item-title>

                                </v-list-item> -->
                                <v-list-item :to="'/mybooking'">
                                    <v-list-item-title> <i class="fas fa-shopping-cart"></i> حجوزاتي</v-list-item-title>

                                </v-list-item>
                                <v-list-item @click="logout">
                                    <v-list-item-title> <i class="fas fa-power-off"></i> تسجيل خروج</v-list-item-title>

                                </v-list-item>


                            </v-list>
                        </v-menu>


                    </div>

<!-- 
                    <div class="hidden-md-and-down d-inline   white--text " style="font-size:18px;padding-right:15%;">
                        <v-btn class="hidden-md-and-down " text depressed style="width: 36px;">
                            {{this.$cookies.get('user_info').name}}
                        </v-btn>
                    </div> -->

                </div>
                <div v-else style="padding: 0px;margin: 0px;">
                    <v-btn small color="warning" fab dark @click="signin_fun()">
                        <v-icon>fas fa-sign-in-alt</v-icon>
                    </v-btn>


                </div>
            </div>




        </div>



        <!-- </v-toolbar-items> -->















        <v-dialog v-model="reg_dialog" max-width="460px" max-height="370px">


            <v-card pb-4>


                <v-layout pl-3>

                    <v-layout row wrap>
                        <v-flex xs6>
                            <v-btn style="width:100%;border-radius:0px" class="reg_btn" v-on:click="signin=true;e1=2"
                                v-bind:class="{btn_active:signin}" height="60">تسجيل الدخول </v-btn>

                        </v-flex>

                        <v-flex xs6>
                            <v-btn style="width:100%;border-radius:0px" class="reg_btn"
                                v-on:click="signin=false;error_msg='';e1=1" v-bind:class="{btn_active:!signin}"
                                height="60">
                                حساب جديد </v-btn>

                        </v-flex>
                    </v-layout>


                </v-layout>




                <br>






                <v-flex xs12 md12 sm12 pt-5>
                    <v-alert type="error" v-if="error_msg.length!==0"
                        style="position: relative;right: 0%;width: 84%;height:50px">

                        <span style="font-family:'droid'!important;" v-for="error in error_msg"
                            :key="error">{{error}}</span>
                    </v-alert>


                </v-flex>
                <v-stepper v-model="e1">
                    <!-- <v-stepper-header>
                            <v-stepper-step :complete="e1 > 1" step="1">Name of step 1</v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step :complete="e1 > 2" step="2">Name of step 2</v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step step="3">Name of step 3</v-stepper-step>
                        </v-stepper-header> -->

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-form v-model="valid" ref="main_info">
                                <v-layout wrap justify-center="">

                                    <v-card-text style="padding:0px !important">
                                        <v-container grid-list-md fluid="">
                                            <v-col v-if="!signin">
                                                <v-row justify="center" class="mb-3">

                                                    <v-avatar height="100" width="100">
                                                        <img class="signup_img" :src="images[0]" :alt="imgname">
                                                    </v-avatar>

                                                </v-row>
                                                <v-row justify="center">
                                                    <v-btn class="btn_active" @click="pickFile">

                                                        <v-icon size="25" style="padding-left :7px">fas fa-camera
                                                        </v-icon>
                                                        صورة
                                                    </v-btn>
                                                </v-row>
                                            </v-col>

                                        </v-container>

                                    </v-card-text>

                                    <v-flex xs11>

                                        <v-text-field label="ألأســم" v-model="name" required v-if="!signin"
                                            :rules="nameRules" autocomplete="off">
                                        </v-text-field>
                                    </v-flex>


<!-- 
                                    <v-flex xs11>
                                        <v-text-field label="ألايمــيل" v-model="email" data-vv-name="email"
                                            v-if="!signin" :rules="emailRules" autocomplete="off"></v-text-field>
                                    </v-flex>



                                    <v-flex xs11>
                                        <v-select :items="cities" item-text="name" v-model="city" item-value="id"
                                            label="اسم المحافظه" required v-if="!signin"></v-select>

                                    </v-flex> -->


                                    <v-flex xs11>
                                        <v-text-field label="رقم الهاتف" autocomplete="off" :rules="phoneRules"
                                            v-model="phone" data-vv-name="phone" type="number">
                                        </v-text-field>
                                    </v-flex>


                                    <v-flex xs11>
                                        <v-text-field label="الباسورد" :rules="passwordRules" required
                                            :type="show1 ? 'text' : 'password'"
                                            :append-icon="show1 ? 'visibility' : 'visibility_off'"
                                            @click:append="show1 = !show1" autocomplete="off" v-model="password">
                                        </v-text-field>


                                    </v-flex>

                                    <v-flex xs11>

                                        <v-text-field v-model="password_confirmation" height="50" style=""
                                            placeholder="اعاده كتابه الباسورد" required
                                            :type="show2 ? 'text' : 'password'"
                                            :append-icon="show2 ? 'visibility' : 'visibility_off'"
                                            @click:append="show2 = !show2" :rules="passwordConfirmationRules">
                                        </v-text-field>
                                    </v-flex>


                                    <v-card-actions>

                                        <v-container>
                                            <v-row justify="center">
                                                <v-flex xs4>
                                                    <!-- <v-btn color="#ff6600" @click="login()" v-if="signin"
                                                        style=";color:#fff;width:100%">
                                                        دخول
                                                    </v-btn> -->
                                                    <v-btn color="#ff6600" @click="sginUp()" v-if="!signin"
                                                        style=";color:#fff;width:100%">
                                                        تسجيل
                                                    </v-btn>
                                                </v-flex>



                                            </v-row>
                                        </v-container>
                                    </v-card-actions>





                                    <input type="file" style="display: none" ref="image" accept="image/*"
                                        @change="onFilePicked">







                                </v-layout>

                                <!-- <v-card-actions>

                                    <v-container>
                                        <v-row justify="center">
                                            <v-flex xs4>
                                                <v-btn color="#ff6600" @click="next()" style=";color:#fff;width:100%">
                                                    التالي
                                                </v-btn>


                                            </v-flex>

                                        </v-row>
                                    </v-container>
                                </v-card-actions> -->
                            </v-form>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-form v-model="valid" ref="auth_info">

                                <v-flex xs11>
                                    <v-text-field label="رقم الهاتف" autocomplete="off" :rules="phoneRules"
                                        v-model="phone" data-vv-name="phone" type="number">
                                    </v-text-field>
                                </v-flex>


                                <v-flex xs11>
                                    <v-text-field label="الباسورد" :rules="passwordRules" required
                                        placeholder="كلمة المرور" height="50" :type="show5 ? 'text' : 'password'"
                                        :append-icon="show5 ? 'visibility' : 'visibility_off'"
                                        @click:append="show5 = !show5" autocomplete="off" v-model="password">
                                    </v-text-field>
                        
                                </v-flex>

                             <v-row>
                                    <br>
                                    <br>
                                            <div style="padding-right:10px">



                                        هل نسيت كلمه السر
                                        <v-btn text  v-on:click="signin=false;error_msg='';e1=3">اضغط هنا</v-btn>
                                    </div>
                             </v-row>
                                <br>
                                <br>
                                <v-card-actions>

                                    <v-container>
                                        <v-row justify="center">
                                            <v-flex xs6>
                                                <v-btn color="#ff6600" @click="login()" v-if="signin"
                                                    style=";color:#fff;width:100%">
                                                    دخول
                                                </v-btn>
                                            </v-flex>



                                        </v-row>
                                    </v-container>
                                </v-card-actions>







                            </v-form>


                        </v-stepper-content>


                        <v-stepper-content step="3">
                            <v-form v-model="valid" ref="auth_forget">

                                <v-flex xs11>
                                    <v-label><span style="color:#000">ادخل رقم الهاتف لاعاده تعين كلمه لسر</span>
                                    </v-label>
                                    <v-text-field placeholder="رقم الهاتف" autocomplete="off" :rules="phoneRules"
                                        v-model="phone_forget" data-vv-name="phone" type="number">
                                    </v-text-field>
                                </v-flex>
                                <v-card-actions>

                                    <v-container>
                                        <v-row justify="center">
                                            <v-flex xs4>
                                                <v-btn color="#ff6600" @click="submit_forget()"
                                                    style=";color:#fff;width:100%">
                                                    ارسال
                                                </v-btn>
                                            </v-flex>
                                        </v-row>
                                    </v-container>
                                </v-card-actions>







                            </v-form>


                        </v-stepper-content>


                        <v-stepper-content step="4">
                            <v-form v-model="auth_re_password" ref="auth_re_password">




                                <v-flex v-flex xs11>
                                    <v-lable>يرجئ كتابه رمز التحقق تم ارساله برساله</v-lable>
                                    <v-text-field height="50" style="" placeholder="رمز التحقق"
                                        v-model="user_forget.verfy_code" required counter-value="4" type="number"
                                        :rules="verfyRules" autocomplete="off"></v-text-field>
                                </v-flex>



                                <v-flex v-flex xs11>
                                    <v-lable>الباسورد</v-lable>
                                    <v-text-field type="password" v-model="user_forget.password" height="50" style=""
                                        placeholder="الباسورد" required :rules="passwordRules">
                                    </v-text-field>
                                </v-flex>

                                <v-flex v-flex xs11>
                                    <v-lable>اعاده كتابه الباسورد</v-lable>
                                    <v-text-field type="password" v-model="user_forget.password_confirmation"
                                        height="50" style="" placeholder="اعاده كتابه الباسورد" required
                                        :rules="passwordConfirmationRules"></v-text-field>
                                </v-flex>
                                <v-card-actions>

                                    <v-container>
                                        <v-row justify="center">
                                            <v-flex xs4>
                                                <v-btn color="#ff6600" @click="changesPassword()"
                                                    style=";color:#fff;width:100%">
                                                    ارسال
                                                </v-btn>
                                            </v-flex>
                                        </v-row>
                                    </v-container>
                                </v-card-actions>







                            </v-form>


                        </v-stepper-content>



                    </v-stepper-items>






                </v-stepper>





            </v-card>

        </v-dialog>



        <v-dialog v-model="verfy_Dailog" max-width="450px" height="300px" persistent>
            <v-card>
                <v-form v-model="active_form" ref="active_form">
                    <v-container>
                        <span class="mb-4">تم ارسال رقم التفعيل برساله يرجئ التحقق</span>
                        <v-text-field label="ادخل رقم التفعيل" v-model="verfy_code" required counter-value="4"
                            type="number" :rules="verfyRules" autocomplete="off" class="mb-6">
                        </v-text-field>

                        <v-row justify="center">
                            <v-flex xs4 pl-3>
                                <v-btn color="success" @click="verfyAccount" style=";color:#fff;width:100%">
                                    تفعيل
                                </v-btn>



                            </v-flex>

                            <v-flex xs4>

                                <v-btn color="red" @click="verfy_Dailog=false" style=";color:#fff;width:100%">
                                    خروج
                                </v-btn>


                            </v-flex>

                        </v-row>
                    </v-container>

                </v-form>
            </v-card>
        </v-dialog>

    </div>

</template>



<script>
    import localstorageservice from "./localstorageservice";
    import {
        USER_ROLE,
        USER_ROLE_GUEST
    } from "./constants";


    const axios = require('axios');
    const toast = require('vue-toasted');
    import {
        EventBus
    } from '../event-bus.js';
    export default {
        props: ['dialog'],
        data() {
            return {
                phone_forget: '',
                email_error: '',
                auth_re_password:false,
                password_confirmation:'',
                show1: false,
                show2: false,
                user_name: '',
                active_form:false,
                user_forget: {
                    password_confirmation: "",
                    password: "",
                    verfy_code: ""
                },
                user_info: {
                    id: '',
                    name: '',
                    session: '',
                    email: '',
                    role: '',
                    photo: ''
                },
                allerros: '',
                from: '',
                token: "",
                provinces: {
                    province_name: ''
                },
                main_info: '',
                phonetoverfie: '',
                signin: true,
                from_post: false,
                valid: false,
                user_imgx: this.$cookies.get('user_info').photo,
                error_msg: '',
                e1: 1,
                name: '',
                email: '',
                cities: [],
                phone: '',
                password: '',
                city: '',
                verfy_code: '',
                verfy_Dailog: false,
                images: [],
                show5: false,
                seigin: true,
                from_booking: false,
                imagesUrl: '',
                auths: false,
                imgname: '',
                user_img: '',
                imageName: '',
                nameRules: [
                    (v) => !!v || 'يجب ادخال الاسم',

                ],
                verfyRules: [
                    (v) => !!v || 'يجب ادخال كود التفعيل',
                    (v) => (v.length == 6) || ' يجب ان يكون كود التفعيل 6 ارقام',
                    (v) => /^\d+$/.test(v) || 'ارقام فقط'

                ],

                phoneRules: [
                    (v) => !!v || 'يجب ادخال رقم الهاتف',
                    (v) => v.length > 10 && v.length < 12 || 'يجب ادخال رقم هاتف صحيح',

                ],





                emailRules: [



                    (v) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || v.length < 1) ||
                    'ألايميل غير صحيح',


                ],




                passwordRules: [
                    v => !!v || 'يجب ادخال الباسورد',
                    (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
                ],
                passwordConfirmationRules: [
                    v => !!v || 'يجب ادخال الباسورد',
                    (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
                    //  (v) => /^.*(?=.{3,})(?=.*[a-zA-Z])/.test(v) || 'يجب ان يحتوي على حروف وارقام',

                ],


                //  user_info: localStorage.getItem('user_info'),
                user_id: localStorage.getItem('user_id'),

                reg_dialog: false,


                data: '',
                desserts: [],
                editedIndex: -1,
                editedItem: {},
                post_dialog: false,
                defaultItem: {


                },

            }
        },

        computed: {

        },

        watch: {

        },


        created() {

            // this.getProvinces();
            // EventBus.$on('reg_dialog', (from) => {
            //     this.from = from;
            //     this.e1 = 2;
            //     this.signin_fun;
            //     this.reg_dialog = true;
            //     this.from_booking = true;

            // });


        },
        updated() {



        },



        methods: {
            submit_forget() {
                if (this.$refs.auth_forget.validate()) {
                    this.loading = true;

                    axios({
                            method: 'post',
                            url: "/auth/v2/forgotPassword/checkPhone",
                            data: {
                                user_phone: "964" + parseInt(this.phone_forget),

                            },
                            headers: {

                            }
                        })
                        .then(response => {
                            response
                            // this.changePassword = true;
                            this.e1 = 4;
                        })
                        .catch(error => {

                                if (error.response) {
                                    this.error_msg = [];
                                    if (error.response.data.data ==
                                        "Account Not Found") {
                                        this.error_msg.push('رقم الهاتف غير مسجل');

                                    }
                                }
                            }


                        )

                        .finally(aa => {
                            aa,
                            this.loading = false;

                        });



                }
            },

            changesPassword() {
                if (this.$refs.auth_re_password.validate()) {
                    this.loading = true;

                    axios({
                            method: 'post',
                            url: "/auth/v2/forgotPassword/changePassword",
                            data: {
                                user_phone: "964" + parseInt(this.phone_forget),
                                password_confirmation: this.user_forget.password_confirmation,
                                password: this.user_forget.password,
                                otpNumber: this.user_forget.verfy_code

                            },
                            headers: {

                            }
                        })
                        .then(response => {
                            response
                            this.$swal('', "تم تغير الباسورد بنجاح",
                                'success')
                            // this.e1=1;
                            this.signin = true;
                            this.e1 = 2
                            //this.signin=true;

                        })
                        .catch(error => {

                                if (error.response) {
                                    this.error_msg = [];
                                    if (error.response.data.data.password ==
                                        "The password confirmation does not match.") {

                                        this.error_msg.push('الباسوردان غير متاطبقان');

                                    }


                                    if (error.response.data.data ==
                                        "otp invalid") {

                                        this.error_msg.push('رمز التحقق غير صحيح');

                                    }


                                }
                            }


                        )

                        .finally(aa => {
                            aa,
                            this.loading = false;

                        });



                }

            },
            getProvinces() {
                this.$http.get("/v2/info/Provinces").then(response => {
                    this.provinces = response.data.data;
                    var i = 0;
                    for (i = 0; i <= this.provinces.length; i++) {

                        this.cities.push(this.provinces[i].province_name);

                    }

                })

            },

            next() {
                if (this.$refs.main_info.validate()) {
                    this.e1 = 2;


                }

            },
            verfyAccount() {
                if (this.$refs.active_form.validate()) {

                    this.$http({
                            method: 'get',
                            url: '/auth/v2/accountActivate/' + this.verfy_code,
                            headers: {
                                //  Authorization: 'Bearer '+this.token
                            }

                        }).then(response => {
                            if (response.data.success == true) {
                                this.$swal('', "شكرا تم تفعيل الحساب بنجاح",
                                    'success')
                                this.verfy_Dailog = false;
                                this.reg_dialog = true;
                                this.signin = true;
                                this.e1 = 2


                            }

                        })
                        .catch(error => {
                            if (error.response.data.success == false) {
                                this.$swal('', 'رمز التفعيل خطاء', 'error')
                            }

                        })


                }
            },
            signin_fun() {

                this.signin = true
                this.e1 = 2;
                this.reg_dialog = true

            },


            signup() {
                this.e1 = 1;

                this.signin = false
                this.reg_dialog = true
                // state.index = state.array.indexOf('you')

            },

            logout() {

                localstorageservice.clearToken();
                this.$cookies.remove("user_info");
                this.$user.set({
                    role: USER_ROLE_GUEST
                });

                this.$router.push({
                    name: 'ألرئيسية',
                });


                location.reload();



            },




            // pickFile() {
            //     this.$refs.image.click()
            // },



            sginUp() {

                //alert(this.city)

                var data = {

                    full_name: this.name,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    user_phone: "964" + parseInt(this.phone),
                    images: this.images,
                  

                };
                // this.description=JSON.stringify(data);

                if (this.$refs.auth_info.validate()) {
                    axios({
                            method: 'post',
                            url: "auth/v2/signUpClient",
                            data: data
                        })
                        .then(response => {
                            response,
                            // this.phonetoverfie = this.phone;
                            this.reg_dialog = false;
                            // this.$swal('', 'تم  انشاء حساب جديد', 'success')
                            localstorageservice.setToken(response.data.token);
                            this.verfy_Dailog = true;
                            //localStorage.setItem('user_info', JSON.stringify(response.data.data));

                            // this.$cookies.set('user_info',response.data.data);
                            //         //    localStorage.setItem('access_token_user', response.data.token);
                            //           this.verfyAccount();

                            //this.login();

                            // if (response.data.success == true) {
                            //     this.$refs.form.reset()
                            //     this.user_id = response.data.user_id;
                            //     this.verfy_Dailog = true;
                            // }
                            // //
                            // switch (this.from) {
                            //     case "mybooking":
                            //         this.$router.push({
                            //             name: 'mybooking'
                            //         })
                            //         break;
                            //     case "fromItem":

                            //         EventBus.$emit('booking_dialog', this.booking_dialog = true);
                            //         break;

                            //         // case 6:
                            //         //     day = "Saturday";
                            // }





                        })
                        .catch(error => {

                            if (error.response) {
                                this.error_msg = [];
                                if (error.response.data.data.password ==
                                    "The password confirmation does not match.") {
                                    this.scrollToTop();
                                    this.error_msg.push('الباسوردان غير متاطبقان');

                                }

                                if (error.response.data.data.user_phone ==
                                    "The user phone has already been taken.") {
                                    this.scrollToTop();
                                    this.error_msg.push('رقم الهاتف مسجل');

                                }



                            }

                        });

                }
            }

            ,

            scrollToTop() {
                window.scrollTo(0, 0);
            },

            clear() {

                this.editedItem.email = ''

                this.$validator.reset()

            },







            cats_filter() {


                // Fire.$emit('cats_fliter');
            },







            login() {



                //Sign In

                if (this.signin) {

                    if (this.$refs.auth_info.validate()) {
                        axios.post('/auth/v2/login', {

                                user_phone: "964" + parseInt(this.phone),
                                password: this.password,

                            }).then(response => {

                                if (response.data.data == 'User Unactive') {

                                    //   this.signin = false;
                                    localstorageservice.setToken(response.data.token);
                                    this.verfy_Dailog = true;

                                }
                                if (response.data.data.user_role == 'client') {

                                    this.$user.set({
                                        role: USER_ROLE
                                    });
                                    this.login_info = response.data.data;
                                    this.user_info.role = this.$user.get().role;
                                    this.user_info.name = this.login_info.user_name;
                                    this.user_info.phone = this.login_info.user_phone;
                                    this.user_info.email = this.login_info.user_email;
                                    this.user_info.email = this.login_info.user_email;
                                    this.user_info.photo = this.login_info.user_image;


                                    if (this.login_info.user_image == null) {


                                        this.user_info.photo =
                                            'https://cdn3.iconfinder.com/data/icons/vector-icons-6/96/256-512.png';


                                    } else {

                                        this.user_info.photo =this.Url+'/images/'+this.login_info.user_image;

                                    }

                                    this.$cookies.set('user_info', this.user_info);
                                    this.user_imgx = this.$cookies.get('user_info').photo;


                                    localstorageservice.setToken(this.login_info.token);
                                    this.phonetoverfie = this.phone;
                                    this.reg_dialog = false;

                                    if (response.data.success == true) {
                                        this.$refs.form.reset()

                                    } else if (response.data.success == false) {
                                        if (response.data.error == "not verified") {
                                            this.user_id = response.data.user_id;
                                            this.verfy_Dailog = true;
                                        }

                                    }

                                    EventBus.$emit('getUserReservationsCount', response.data.data.token);
                                    switch (this.from) {
                                        case "mybooking":
                                            this.$router.push({
                                                name: 'mybooking'
                                            })
                                            break;
                                        case "fromItem":

                                            EventBus.$emit('booking_dialog', this.booking_dialog = true);
                                            break;

                                            // case 6:
                                            //     day = "Saturday";
                                    }

                                    toast({
                                        type: 'success',
                                        title: 'تم تسجيل الدخول بنجاح'
                                    })



                                } else {
                                    this.error_msg = 'يجب ان يكون حساب يوزر';
                                }









                            })
                            .catch(error => {

                                this.error_msg = 'رقم الهاتف او الباسورد غير صحيح';
                                this.phonetoverfie = this.phone;
                                if (error.response.data.error == "not verified") {
                                    this.user_id = error.response.data.user_id;
                                    this.verfy_Dailog = true;
                                }


                            }).finally((w) => {
                                w,

                                EventBus.$emit('getUserReservationsCount', true);
                            });






                        //Sign Up

                    } else {


                        this.error_msg = '';
                        this.sginUp();



                    }





                    //     }

                }

            },
            close() {

                this.imageName = ''
                this.imageFile = ''
                this.imageUrl = ''
                this.allerros = ' ';
                this.error_msg = ' ',
                    this.editedItem = [],
                    this.$refs.form.reset()
                this.reg_dialog = false

            },



            pickFile() {

                this.$refs.image.click()
            },

            onFilePicked(e) {

                const files = e.target.files
                if (files[0] !== undefined) {
                    this.imageName = files[0].name
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result
                        this.imageFile = files[0]
                        this.images = [];
                        this.images.push(fr.result);
                        this.imgname = files[0].name;


                    })

                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },
        },


        mounted() {



        }

    }
</script>