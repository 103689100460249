// localstorageservice.js
const localstorageservice = (function(){
 var _service;
 function _getServiceUser() {
     if(!_service) {
       _service = this;
       return _service
   }
   return _service
 }

 function _getServiceOwner() {
     if(!_service) {
       _service = this;
       return _service
   }
   return _service
 }

 function _getServiceAdmin() {
     if(!_service) {
       _service = this;
       return _service
   }
   return _service
 }





 function _setTokenUser(tokenObj) {

   localStorage.setItem('access_token_user', tokenObj);
   localStorage.setItem('refresh_token_user', tokenObj);
 }



 function _setTokenOwner(tokenObj) {
   localStorage.setItem('access_token_owner', tokenObj);
   localStorage.setItem('refresh_token_owner', tokenObj);
 }


 function _setTokenAdmin(tokenObj) {
   localStorage.setItem('access_token_admin', tokenObj);
   localStorage.setItem('refresh_token_admin', tokenObj);
 }



 function _getAccessTokenUser() {
   return localStorage.getItem('access_token_user');
 }

 function _getAccessTokenOwner() {
   return localStorage.getItem('access_token_owner');
 }


 function _getAccessTokenAdmin() {
   return localStorage.getItem('access_token_admin');
 }




 function _getRefreshTokenOwner() {
   return localStorage.getItem('refresh_token_owner');
 }

 function _getRefreshTokenUser() {
   return localStorage.getItem('refresh_token_user');
 }

 function _getRefreshTokenAdmin() {
   return localStorage.getItem('refresh_token_admin');
 }







 function _clearTokenUser() {
   localStorage.removeItem('access_token_user');
   localStorage.removeItem('refresh_token_user');
 }

 function _clearTokenOwner() {
   localStorage.removeItem('access_token_owner');
   localStorage.removeItem('refresh_token_owner');
 }


 function _clearTokenAdmin() {
   localStorage.removeItem('access_token_admin');
   localStorage.removeItem('refresh_token_admin');
 }



 var x=window.location.pathname;
 if(x=='/')
 {

   return {
      getService : _getServiceUser,
      setToken : _setTokenUser,
      getAccessToken : _getAccessTokenUser,
      getRefreshToken : _getRefreshTokenUser,
      clearToken : _clearTokenUser
    }

 }
 else if(x=="/owner"){

   return {
      getService : _getServiceOwner,
      setToken : _setTokenOwner,
      getAccessToken : _getAccessTokenOwner,
      getRefreshToken : _getRefreshTokenOwner,
      clearToken : _clearTokenOwner
    }

 }


 else if(x=="/admin"){

   return {
      getService : _getServiceAdmin,
      setToken : _setTokenAdmin,
      getAccessToken : _getAccessTokenAdmin,
      getRefreshToken : _getRefreshTokenAdmin,
      clearToken : _clearTokenAdmin
    }

 }


})();
export default localstorageservice;
